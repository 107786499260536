















































































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import {
    apiWechatMiniSetting,
    apiWechatMiniSettingSet
} from '@/api/channel/wechat_app'
import {
    WechatMiniSetting_Res,
    WechatMiniSetting_Req
} from '@/api/channel/wechat_app.d'
import { copyClipboard } from '@/utils/util'
@Component({
    components: {
        MaterialSelect
    }
})
export default class WechatApp extends Vue {
    /** S Data **/
    form: WechatMiniSetting_Res = {
        name: '', // 小程序名称
        original_id: '', // 原始id
        qr_code: '', // 二维码
        app_id: '',
        app_secret: '',
        request_domain: '', // request合法域名
        socket_domain: '', // socket合法域名
        upload_file_domain: '', // uploadFile合法域名
        download_file_domain: '', // downloadFile合法域名
        udp_domain: '', // udp合法域名
        tcp_domain: '',
        business_domain: '', // 业务域名
        url: '',
        token: '',
        encoding_aes_key: '',
        encryption_type: 1, // 消息加密方式 1-明文模式 2-兼容模式 3-安全模式
        data_format: 1 // 数据格式 1-JSON 2-XML
    }

    // 表单验证
    formRules = {
        app_id: [
            {
                required: true,
                message: '必填项不能为空',
                trigger: 'blur'
            }
        ],
        app_secret: [
            {
                required: true,
                message: '必填项不能为空',
                trigger: 'blur'
            }
        ]
    }

    $refs!: {
        formRef: any
    }
    /** E Data **/

    // 获取APP设置
    getWechatAppSetting() {
        apiWechatMiniSetting()
            .then((res: any) => {
                this.form = res
                // this.$message.success('获取数据成功!')
            })
            .catch(() => {
                //this.$message.error('获取数据失败!')
            })
    }

    // 修改APP设置
    putWechatAppSetting() {
        this.$refs.formRef.validate((valid: boolean) => {
            // 预校验
            if (!valid) {
                return this.$message.error('请完善信息')
            }
            apiWechatMiniSettingSet(this.form)
                .then((res: any) => {
                    this.getWechatAppSetting()
                    //this.$message.success('保存成功!')
                })
                .catch(() => {
                    //this.$message.error('保存失败!')
                })
        })
    }
    // 复制到剪贴板
    onCopy(value: string) {
        copyClipboard(value)
            .then(() => {
                this.$message.success('复制成功')
            })
            .catch(err => {
                this.$message.error('复制失败')
            })
    }

    /** S Life Cycle **/
    created() {
        this.getWechatAppSetting()
    }
    /** E Life Cycle **/
}
